.ant-btn.ant-btn-primary.login-form-btn {
  width: 100%;
  background: black !important;
  border-color: black;
  border-radius: 4px;
  box-shadow: 0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(100 100 100 / 40%);
  font-size: 20px;
  height: 70px;
}

.link-text {
  color: #1890FF !important;
  font-size: 20px;
}

.large-input, .large-input > .ant-input  {
  font-size: 20px !important;
}