.card-full-width {
  width: 100% !important;
}

.button-full-width, .button-full-widthn.ant-upload {
  width: 100% !important;
}

.button-full-width .ant-upload.ant-upload-select {
  display: block;
}

.in-cell-row {
  margin-top: 10px;
  margin-bottom: 10px;
}

.in-cell-row:last-child {
  margin-bottom: 0px;
}

.in-cell-row:first-child {
  margin-top: 0px;
}

.in-cell-card-title {
  margin-bottom: 0.5em !important;
}

.detection-checkboxes {
  margin-left: 10px !important;
  margin-bottom: 15px !important;
}

#wave-spectrogram{
  margin: 10px auto;
  position: relative;
}
#wave-spectrogram canvas{
  left: 0;
}

.spect-preview {
  width: 50px;
  max-height: 75px;
}

.spect-preview-large {
  max-width: calc(100vw - 32px);
  max-height: calc(100vh - 32px);
}

.arch-switch {
  margin-right: 8px !important;
  /* margin-top: 5px !important; */
}

.ant-switch-handle {
  position: absolute;
  top: 7px !important;
  left: 6px !important;
  width: 18px;
  height: 18px;
}

.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 6px) !important;
}

.ant-switch.arch-switch {
  height: 32px;
  line-height: 32px;
}

.ant-switch-checked .ant-switch-inner {
  margin: 0 28px 0 10px !important;
}

.ant-switch-inner {
  margin: 0 10px 0 28px !important;
}

.ant-switch {
  border-radius: 10px !important;
}

.archived-annotation {
  display: block;
  /* margin-top: 10px; */
  margin-bottom: 10px;
}