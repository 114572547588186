.forgot-password-form-btn {
  width: 100%;
  background: black !important;
  border-color: black !important;
  box-shadow: 0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(100 100 100 / 40%) !important;
  font-size: 20px;
  height: 70px;
}

.large-input, .large-input > .ant-input  {
  font-size: 20px !important;
}