.state-marker {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.btn-label-delete {
  background: linear-gradient(60deg, #ca1613, #94100e);
  box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.1), 0 2px 3px 0px rgba(148, 16, 14, 0.4);
}

.state-marker.danger {
  background: linear-gradient(60deg, #ef5350, #e53935);
  box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.1), 0 2px 3px 0px rgba(244, 67, 54, 0.4);
}

.state-marker.warning {
  background: linear-gradient(60deg, #ffa726, #fb8c00);
  box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.1), 0 2px 3px 0px rgba(251, 140, 0, 0.4);
}

.state-marker.overdue {
  background: linear-gradient(60deg, #fdd835, #fbc02d);
  box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.1), 0 2px 3px 0px rgba(251, 192, 45, 0.4);
}

.state-marker.success {
  background: linear-gradient(60deg, #66bb6a, #43a047);
  box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.1), 0 2px 3px 0px rgba(76, 175, 80, 0.4);
}

.state-marker.info {
  background: linear-gradient(60deg, #26c6da, #00acc1);
  box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.1), 0 2px 3px 0px rgba(0, 172, 193, 0.4);
}

.state-marker.default {
  background: linear-gradient(60deg, #40a9ff, #1890ff);
  box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.1), 0 2px 3px 0px rgba(24, 144, 255, 0.4);
}

.state-marker.muted {
  background: linear-gradient(60deg, #ffffff, #f2f2f2);
  box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.1), 0 2px 3px 0px rgba(140, 140, 140, 0.4);
}

