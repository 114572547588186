.admin-sider {
  min-height: 100vh;
}

.admin-header {
  background: "#fff"; 
  padding: 10px;
  box-shadow: 0 7px 10px 2px rgb(0 0 0 / 10%);
}

.admin-content {
  background: "#eee" !important;
  padding: 36px;
} 

.admin-footer {
  text-align: center !important;
  background-color: white !important;
  box-shadow: 0 -7px 10px 2px rgb(0 0 0 / 10%);
}