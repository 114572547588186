@import '~antd/dist/antd.css';

.base-header {
  background: transparent;
  height: 0px;
  padding: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
}

.base-content {
  /* background: url('bg.png') no-repeat center center fixed; */
  background-color: white;
  /* -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover; */
  /* background-size: cover; */
}

.base-footer {
  text-align: center; 
  background-color: #FFF;
}

.login-button {
  margin: 20px;
}

