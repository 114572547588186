.nav-logo {
  width: 70px;
  /* background-size: contain; */
  margin: 25px;
}

.nav-logo-wrapper {
  width: 150px;
  display: block;
  margin-top: 70px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}