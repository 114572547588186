.ant-btn :not(.ant-btn-circle), 
.ant-btn :not(.ant-btn-round) {
  border-radius: 4px !important;
}

.ant-input, .ant-input-number, .ant-input-password {
  border-color: #CCCCCC !important;
  border-radius: 4px !important;
  color: rgba(0, 0, 0, 0.65) !important;
}

.ant-btn :not(.ant-btn-primary) {
  border-color: #CCCCCC;
}

.ant-btn.ant-btn-primary {
  background: linear-gradient(60deg, #40A9FF, #1890FF);
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(24, 144, 255, 0.4);
}

.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  text-shadow: none;
  box-shadow: none;
}

.ant-card {
  border-radius: 4px !important;
  box-shadow: 0 7px 20px 2px rgb(0 0 0 / 20%);
  border-width: 0px !important;
}

.ant-table-thead > tr > th {
  height: 50px;
  border-bottom: 1px solid #DDDDDD;
  border-top: 1px solid #DDDDDD;
}

.ant-table.ant-table-small .ant-table-thead > tr > th, 
.ant-table.ant-table-small .ant-table-tbody > tr > td {
  padding-left: 10px;
}

h5.ant-typography {
  margin-bottom: 1em;
}

.ant-layout-sider-zero-width-trigger {
  top: 10px;
}

.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon, 
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #1890FF;
}

