.info-card-bg {
  /* border: 1px solid black !important; */
  border-radius: 4px !important;
  border: 0 !important;
  /* background: rgba(255, 245, 255, 0.9) !important; */
  background-color: lightgray !important;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  margin: auto !important;
  margin-top: 70px !important;
  margin-bottom: 70px;
  width: 50%;
  min-width: 450px;
  box-shadow: none !important;
}

.info-card-bg .ant-card-body {
  padding-bottom: 10px !important;
}

.info-card-title-div {
  padding: 0;
  position: relative;
  padding: 15px;
  margin-top: -40px;
  margin-bottom: 30px;
  border-radius: 3px;
  text-align: center;
}

.info-card-title {
  color: #FFF !important;
  margin-bottom: 5px !important;
  font-weight: 300 !important;
}


.default {
  /* background: linear-gradient(60deg, #40a9ff, #1890ff); */
  background-color: black;
  /* box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255,255, 255, 0.4); */
}